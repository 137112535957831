import { create } from "zustand";
import React from "react";

type EditorStore = {
  currentDocumentKey: string;
  showEditorPlaceholder: boolean;
  editorJSHolderRef: React.RefObject<HTMLDivElement>;
  updateNoteContent: (hasContent: boolean) => void;
  focusEditor: () => void;
  blurEditor: (hasContent: boolean) => void;
  initializeEditor: () => void;
  editorDocumentTitle: string;
  setEditorDocumentTitle: (title: string) => void;
};

const editorJSHolderRef = React.createRef<HTMLDivElement>();

const useEditorStore = create<EditorStore>((set, get) => ({
  currentDocumentKey: "",
  showEditorPlaceholder: true,
  editorJSHolderRef,
  editorDocumentTitle: "",
  setEditorDocumentTitle: (title: string) => {
    set({
      editorDocumentTitle: title,
    });
  },
  updateNoteContent: (hasContent: boolean) => {
    set({
      showEditorPlaceholder: !hasContent,
    });
  },
  focusEditor: () => {
    set({
      showEditorPlaceholder: false,
    });
  },
  blurEditor: (hasContent: boolean) => {
    set({
      showEditorPlaceholder: !hasContent,
    });
  },
  initializeEditor: () => {
    set({
      currentDocumentKey: "",
      showEditorPlaceholder: true,
    });
  },
}));

export default useEditorStore;
